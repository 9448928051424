export const ScoringFormulaList = [
    {
        label: 'Industry Standards',
        key: 'Series'
    },
    {
        label: 'Sensitive',
        key: 'Sensitive'
    },
    // {
    //     label: 'Default',
    //     key: 'Default'
    // },
]
