export default {
  getActiveEntity: (state) => state.activeEntity || [],
  getEntityPaymentServiceList: (state) => state.entityPaymentServiceList || {},
  getEntityPaymentMethodList: (state) => state.entityPaymentMethodList || {},
  getEntityProvidedServiceList: (state) => state.entityProvidedServiceList || {},
  getEntityBanksList: (state) => state.entityBanksList || {},
  getEntityTagsList: (state) => state.entityTagsList || {},
  getEntityTypesList: (state) => state.entityTypesList || {},
  getEntityStatusList: (state) => state.entityStatusList || {},
  getEntityKYCList: (state) => state.entityKYCList || {},
  getIsFiatCurrencyTradingList: (state) => state.isFiatCurrencyTradingList || {},
  getIsPrivacyCoinsSupportedList: (state) => state.isPrivacyCoinsSupportedList || {},
  getEntityPaymentTypesList: (state) => state.entityPaymentTypesList || {},
}