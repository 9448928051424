export default {
  SET_UG_PAGINATION_CURRENT_PAGE(state, payload) {
    state.ugPagination.currentPage = payload
  },
  SET_UG_PAGINATION_TOTAL(state, payload) {
    state.ugPagination.totalPages = Math.ceil((payload + 1) / state.ugPagination.perPage)
  },
  SET_UG_CARDS(state, payload) {
    const processedPayload = payload.map(item => {
      if (item.description === 'null') {
        return { ...item, description: null }
      }
      return item
    })
    state.ugCards = processedPayload
  },
  SET_UG_TOOLTIP_STATE(state, payload) {
    state.ugTooltip.state = payload
  },
  SET_UG_TOOLTIP_LINK(state, payload) {
    state.ugTooltip.link = payload
  },
  SET_GM_PAGINATION_CURRENT_PAGE(state, payload) {
    state.gmPagination.currentPage = payload
  },
  SET_GM_PAGINATION_TOTAL(state, payload) {
    state.gmPagination.totalPages = Math.ceil((payload + 1) / state.gmPagination.perPage)
  },
  SET_GM_TABLE(state, payload) {
    const processedPayload = payload.map(item => {
      if (item.description === 'null') {
        return { ...item, description: null }
      }
      return item
    })
    state.gmTable = processedPayload
  },
}
